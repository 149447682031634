import React, { useMemo } from "react";
import { MessageCenterContext } from "./MessageCenterContext"
import { MessageCenter } from "../MessageCenter";
import {ProviderType} from "./types";

/**
 * 
 * @param param0.context a React.Context object
 * @param param0.children React sub components
 */
export function MessageCenterProvider({ context, userId, firebaseServices, children }: ProviderType) {
    const contextValue = useMemo(() => {
        return userId !== undefined ? ({
            messageCenter: new MessageCenter(firebaseServices),
        }) : ({
            messageCenter: undefined,
        });
    }, [userId, firebaseServices.auth?.currentUser?.uid]);
    const Context = context || MessageCenterContext;
    return <Context.Provider value={contextValue} > {children} </Context.Provider>
}