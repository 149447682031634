import { LogType } from "./types";

export const Log = (prefix: string = ""): LogType => ({
    debug: (...data: any) => console.debug(`MessageCenter:${prefix}: `, ...data),
    error: (...data: any) => console.error(`MessageCenter:${prefix}: `, ...data),
    warn: (...data: any) => console.warn(`MessageCenter:${prefix}: `, ...data)
});

export const createMessageId = () => {
    const timestamp = (new Date()).getTime(); // unix timestamp in milliseconds
    return `${timestamp}`;
}