import {
    ChatConstructorServicesType,
    ChatInterface,
    ChatMetaType,
    MessageType,
    RawMessageType,
    RealtimeMessageType,
    UserInfoType,
    UserType,
    LogType
} from "./types";
import {
    ChatStatuses,
    CountEvents,
    MessageEvents,
    MessageStatus,
    RealtimeServiceEvents,
} from "./constants";
import firebase from "firebase";
import { EventEmitter } from "events";
import { createMessageId } from "./utils";

/**
 * 
 */
export class Chat extends EventEmitter implements ChatInterface {

    public totalCount: number = 0;
    public usedCount: number = 0;
    public expiryDate: Date = new Date();
    public sender?: UserType;
    public recipient?: UserType;
    public status: ChatStatuses = ChatStatuses.NOTREADY;
    public lastMessage?: MessageType;

    protected meta?: ChatMetaType;
    protected currentUser: firebase.User;
    protected conversationRefName: string = "conversation";    
    protected unreadCount?: number = 0;

    /**
     * 
     * @param {String} id main thread id
     * @param {ChatConstructorServicesType} services 
     * @param {LogType} log 
     */
    constructor(public id: string, protected services: ChatConstructorServicesType, private log: LogType) {
        super();
        if (!this.services.auth.currentUser) {
            throw new Error(`NO_SIGNED_IN_USER_FOUND`);
        }
        this.currentUser = this.services.auth.currentUser;
        this.initialize();
    }

    /**
     * 
     */
    async initialize() {
        this.status = ChatStatuses.INPROGRESS;
        this.emit(this.status);
        try {
            await this.initializeCounts();
            await this.initializeExpiryDate();
            await this.initializeMeta();
            await this.initializeSender();
            await this.initializeRecipient();
            await this.initializeRealtimeServices();
            await this.listenForMessageEvents();
            this.status = ChatStatuses.READY;
            this.emit(this.status);
        } catch (e) {
            this.log.error(`Error while initializing the chat`, e);
            this.status = ChatStatuses.ERROR;
            this.emit(this.status, e);
        }
    }

    /**
     * 
     */
    async initializeCounts() {
        this.usedCount = 0;
        this.totalCount = Infinity;
    }

    /**
     * 
     */
    async initializeExpiryDate() {
        const expiry = new Date();
        expiry.setTime(expiry.getTime() + (1 * 24 * 60 * 60 * 1000)); // 1 full day
        this.expiryDate = expiry;
    }

    async initializeMeta() {
        await this.services.realtimeRef
            .child("meta")
            .once("value")
            .then((snapshot) => {
                if (!snapshot.exists()) {
                    return;
                }
                const meta: ChatMetaType = snapshot.val();
                this.meta = meta;
            }).catch((e) => {
                this.log.error(`Error while getting chat meta: `, e);
            });
    }

    /**
     * 
     */
    async initializeSender() {
        try {
            const members = this.meta?.members;
            const senderId = this.currentUser.uid;
            if (!members || !members.includes(senderId)) {
                throw new Error(`INVALID_MEMBERS LIST`);
            }
            const userDetails: {
                firstName: string,
                lastName: string,
                profilePicture?: string
            } = await this.getUserInfo(senderId);
            this.sender = {
                name: `${userDetails.firstName} ${userDetails.lastName}`,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                id: senderId,
                profilePicture: userDetails.profilePicture
            };
        } catch (e) {
            this.log.error(`Error while initializing sender: ${this.currentUser?.uid}: `, e);
        }
    }

    /**
     * 
     */
    async initializeRecipient() {
        try {
            const members = this.meta?.members;
            if (!members) {
                throw new Error(`INVALID_MEMBERS LIST`);
            }
            const senderId = this.currentUser.uid;
            const recipientId = members.filter(m => m !== senderId).pop(); // only one recipient for now
            if (!recipientId) {
                throw new Error(`RECIPIENT_NOT_FOUND`);
            }
            const userDetails = await this.getUserInfo(recipientId);
            this.recipient = {
                name: `${userDetails.firstName} ${userDetails.lastName}`,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                id: recipientId,
                profilePicture: userDetails.profilePic?.downloadURL || ""
            };
        } catch (e) {
            this.log.error(`Error while initializing recipient: ${this.currentUser?.uid}: `, e);
        }
    }

    /**
     * retrieve user's basic informations
     * 
     * @param {String} userId 
     */
    async getUserInfo(userId: string): Promise<UserInfoType> {
        return await this.services.functions.httpsCallable("users")({
            actionType: "GET_OTHER_USER_INFO",
            userId: userId
        }).then(({ data }) => {
            if (data.status !== "success") {
                throw new Error(data.message || data.code || `An error has occurred in getting connected people`);
            }
            return data.data;
        });
    }

    protected async listenForMessageEvents() {
        this.listenForDeletingMessages();
        this.listenForNewMessages();
        this.listenForUpdateInMessages();
        this.listenForSeenMessages();
        this.listenForMyCountUpdates();
        this.listenForLastMessage();
    }

    private async listenForLastMessage() {
        await this.services.realtimeRef
            .child("meta")
            .child("lastMessage")
            .on("value", (snapshot) => {
                if (!snapshot.exists()) {
                    return;
                }
                const lastMessage: RealtimeMessageType | undefined = snapshot.val();
                if (!lastMessage) {
                    return;
                }
                this.lastMessage = this.formatRealtimeMessageToMessage(lastMessage);
                this.emit(MessageEvents.LAST_MESSAGE, this.lastMessage);
            }, (e) => {
                this.log.error(`Error while getting chat meta: `, e);
            });
    }

    private listenForMyCountUpdates() {
        //check changes in unread count and emit
        this.services.unreadCountRef
            .child(this.currentUser.uid)
            .child(this.id).on("value", (snapshot) => {
                if (!snapshot.exists()) {
                    return;
                }
                const count: number = snapshot.val();
                this.unreadCount = count;
                this.emit(CountEvents.Unread, count);
            });

        //check changes in sent count and emit
        this.services.sentCountRef
            .child(this.currentUser.uid)
            .child(this.id).on("value", (snapshot) => {
                if (!snapshot.exists()) {
                    return;
                }
                const count: number = snapshot.val();
                this.emit(CountEvents.Sent, count);
            });
    }

    /**
     * this will listen for new messages
     * and on encountering, it will trigger 
     * `MessageEvents.NEW` event
     */
    private listenForNewMessages() {
        this.services.realtimeRef.child(this.conversationRefName).orderByKey().limitToLast(10)
            .on("child_changed", (childSnapshot) => {
                if (!childSnapshot.exists()) {
                    return;
                }
                const message: RealtimeMessageType = childSnapshot.val();
                this.emit(MessageEvents.NEW, this.formatRealtimeMessageToMessage(message));
            });
    }

    /**
     * this will listen for deleting messages
     * and on encountering, it will trigger 
     * `MessageEvents.DELETED` event
     */
    private listenForDeletingMessages() {
        this.services.realtimeRef.child(this.conversationRefName)
            .on("child_changed", (childSnapshot) => {
                if (!childSnapshot.exists()) {
                    return;
                }
                const senderId = this.currentUser.uid;
                const message: RealtimeMessageType = childSnapshot.val();
                //if previous value does not have current user id
                // but current value have its id then it means
                // the message is deleted by the sender
                if (!message.previousValue?.deletedBy?.includes(senderId)
                    && message.deletedBy?.includes(senderId)) {
                    this.emit(MessageEvents.DELETED, this.formatRealtimeMessageToMessage(message))
                }
            });
    }

    /**
     * this will listen for seen change in the messages
     * and encountering this change it will trigger 
     * `MessageEvents.SEEN` event
     */
    private listenForSeenMessages() {
        this.services.realtimeRef.child(this.conversationRefName)
            .on("child_changed", (childSnapshot) => {
                if (!childSnapshot.exists()) {
                    return;
                }
                const message: RealtimeMessageType = childSnapshot.val();
                if (!message.previousValue?.seenAt && message.seenAt) {
                    this.emit(MessageEvents.SEEN, this.formatRealtimeMessageToMessage(message))
                } else if (message.previousValue?.seenAt && !message.seenAt) {
                    //has marked the message as unread. so an unseen event will be triggered
                    this.emit(MessageEvents.UNSEEN, this.formatRealtimeMessageToMessage(message))
                }
            });
    }

    /**
     * this will listen for any change in the messages
     * and encountering any change it will trigger 
     * `MessageEvents.UPDATED` event
     */
    private listenForUpdateInMessages() {
        this.services.realtimeRef.child(this.conversationRefName)
            .on("child_changed", (childSnapshot) => {
                if (!childSnapshot.exists()) {
                    return;
                }
                const message: RealtimeMessageType = childSnapshot.val();
                this.emit(MessageEvents.UPDATED, this.formatRealtimeMessageToMessage(message));
            });
    }

    formatMessageToRealtimeMessage(message: MessageType): RealtimeMessageType {
        let result: RealtimeMessageType = {
            ...message,
            previousValue: (message.previousValue !== null && message.previousValue) ? this.formatMessageToRealtimeMessage(message.previousValue) : undefined,
            createdAt: message.createdAt.getTime(),
            updatedAt: message.updatedAt.getTime(),
            seenAt: message.seenAt?.getTime()
        };
        if (!message.seenAt) {
            delete result.seenAt
        }
        if (!message.previousValue) {
            delete result.previousValue
        }
        return result;
    }
    formatRealtimeMessageToMessage(message: RealtimeMessageType): MessageType {
        let result: MessageType = {
            ...message,
            previousValue: (message.previousValue !== null && message.previousValue) ? this.formatRealtimeMessageToMessage(message.previousValue) : undefined,
            createdAt: new Date(message.createdAt),
            updatedAt: new Date(message.updatedAt),
            seenAt: new Date(message.seenAt || 0)
        };
        if (!message.seenAt) {
            delete result.seenAt
        }
        if (!message.previousValue) {
            delete result.previousValue
        }
        return result;
    }

    private initializeRealtimeServices() {
        this.on(RealtimeServiceEvents.NEW, async (message: RealtimeMessageType) => {
            await this.services.realtimeRef
                .child(this.conversationRefName)
                .child(message.id)
                .set(message)
                .catch((e) => {
                    this.emit(MessageEvents.ERROR, {
                        ...message,
                        status: MessageStatus.FAILED
                    });
                    this.log.error(`Error while uploading a new message on realtime: `, message, e);
                });
        });
        this.on(RealtimeServiceEvents.SYNC, async (message: RealtimeMessageType) => {
            await this.services.realtimeRef
                .child(this.conversationRefName)
                .child(message.id)
                .set(message)
                .catch((e) => {
                    this.emit(MessageEvents.ERROR, message);
                    this.log.error(`Error while syncing message to realtime: `, message, e);
                });
        });
    }

    /**
     * 
     */
    async canSend() {
        return this.status === ChatStatuses.READY;
    }

    /**
     * 
     * @param {RawMessageType} message 
     */
    async send(message: RawMessageType) {
        const senderId = this.currentUser.uid;
        const id = createMessageId();
        const messageObject: MessageType = {
            id,
            text: message.text,
            status: MessageStatus.SENDING,
            deletedBy: [],
            createdBy: senderId,
            createdAt: new Date(),
            updatedAt: new Date(),
            updatedBy: senderId
        };

        if (message.file && message.file.url && message.file.type) {
            messageObject.file = {
                url: message.file.url,
                name: message.file.name,
                type: message.file.type
            }
        }
        /**
         * deliver the message to to sending 
         * service which is listening on `RealtimeServiceEvents.NEW`
         * event.
         */
        this.emit(RealtimeServiceEvents.NEW, this.formatMessageToRealtimeMessage(messageObject));
        return messageObject;
    }

    protected async uploadFile(file: File) {
        const storageRef = this.createStorageRef();
        const uploadTask = await storageRef.put(file);
        const downloadURL = await uploadTask.ref.getDownloadURL();
        return downloadURL;
    }

    /**
     * this will create a ref to location where you
     * can upload a file.
     * 
     * **Note:** Every new call to this function will create a new reference
     */
    public createStorageRef() {
        const timestamp = (new Date()).getTime();
        return this.services.storage.ref(`message-center/${this.id}/${this.currentUser.uid}/${timestamp}`);
    }


    /**
     * 
     * @param {String} messageId 
     */
    async delete(messageId: string) {
        return await this.getMessage(messageId)
            .then((message: MessageType) => {
                return {
                    ...message,
                    previousValue: message,
                    deletedBy: [
                        ...message.deletedBy,
                        this.currentUser.uid
                    ],
                    updatedAt: new Date(),
                    updatedBy: this.currentUser.uid
                }
            }).then((message: MessageType) => {
                this.emit(RealtimeServiceEvents.SYNC, this.formatMessageToRealtimeMessage(message));
                return message;
            })
    }

    async getMessage(messageId: string) {
        return await this.services.realtimeRef
            .child(this.conversationRefName)
            .child(messageId)
            .once("value")
            .then((snapshot) => {
                if (!snapshot.exists()) {
                    throw new Error(`Message not found: id: ${messageId}`);
                }
                const message: RealtimeMessageType = snapshot.val();
                return this.formatRealtimeMessageToMessage(message);
            });
    }

    /**
     * 
     * @param {String} messageId 
     */
    async markMessageUnread(messageId: string) {
        let msg: MessageType;
        return this.getMessage(messageId)
            .then((message: MessageType) => {
                if (!message.seenAt) {
                    throw new Error(`Message is already unread: ${message}`);
                }
                delete message.seenAt;
                return {
                    ...message,
                    previousValue: message,
                    updatedBy: this.currentUser.uid,
                    updatedAt: new Date()
                }
            }).then((message: MessageType) => {
                this.emit(RealtimeServiceEvents.SYNC, this.formatMessageToRealtimeMessage(message));
                return message;
            }).catch((e) => {
                console.log(`Error while marking message as unseen: `, e);
                return msg;
            });
    }

    /**
     * 
     * @param {String} messageId 
     */
    async markMessageRead(messageId: string) {
        let msg: MessageType;
        return this.getMessage(messageId)
            .then((message: MessageType) => {
                msg = message;
                if (message.seenAt) {
                    throw new Error(`Message is already marked as read: ${messageId}`);
                }
                return {
                    ...message,
                    previousValue: message,
                    seenAt: new Date(),
                    updatedAt: new Date(),
                    updatedBy: this.currentUser.uid,
                    status: MessageStatus.SENT
                }
            }).then((message: MessageType) => {
                this.emit(RealtimeServiceEvents.SYNC, this.formatMessageToRealtimeMessage(message));
                return message;
            }).catch((e) => {
                console.log(`Error while marking message as seen: `, e);
                return msg;
            });
    }

    /**
     * this will get the latest messages. If you dont
     * specify any parameters in it, it will bring the last 20 messages.
     * But if you want to fetch more than 20 last messages then specify the value in
     * limit parameter.
     * 
     * If you dont want to bring latest messages
     * but messages before a specific date then pass the timestamp
     * parameter
     * 
     * Example 1: Fetch last 50 messages
     * ```typescript
     * const chat: Chat;
     * const messages: MessageType[] = await chat.getLastMessages({limit: 50})
     * ```
     * 
     * Example 2: Fetch last 30 messages before Morning 10 AM
     * ```typescript
     * const chat: Chat;
     * const timestamp = (new Date("2021-06-17T10:00:00")).getTime(); // timestamp in milliseconds
     * const message: MessageType[] = await chat.getLastMessages({limit: 30, timestamp: timestamp});
     * 
     * ```
     * This will bring you 30 messages before a specific time.
     *  
     * 
     * 
     * @param {number} param0.limit number of last messages to fetch
     * @param {Object} param0.timestamp a time value in milliseconds to fetch messages
     * which have been sent before this time.
     */
    async getLastMessages({ limit, timestamp }: { limit?: number, timestamp?: number } = {}) {
        limit = limit || 20; //default limit is 20;
        let queryRef = this.services.realtimeRef
            .child(this.conversationRefName)
            .orderByChild("createdAt");
        if (timestamp) {
            queryRef = queryRef.endAt(timestamp);
        }
        queryRef = queryRef.limitToLast(limit);
        const lastMessages: MessageType[] = await queryRef.once("value").then((snapshot) => {
            if (!snapshot.exists()) {
                return [];
            }
            const rmessages: RealtimeMessageType[] = [];
            snapshot.forEach((childSnapshot) => { //forEach used to preserve the ordering
                rmessages.push(childSnapshot.val());
            });
            return rmessages.reverse();
        }).then((messages) => {
            return messages.map((message) => {
                return this.formatRealtimeMessageToMessage(message);
            });
        }).catch((e) => {
            this.log.error(`Error while fetching last messages: `, e);
            return [];
        });
        return lastMessages;
    }

    /**
     * 
     */
    getUnreadCount() {
        return this.unreadCount || 0;
    }
}
