import {
    MessageCenterInterface,
    MessageCenterServicesType,
    LogType,
    ConnectedPeopleType
} from "./types";
import { Chat } from "./Chat";
import { Log } from "./utils";
import { EventEmitter } from "events";
import { CountEvents } from './constants';
import firebase from "firebase";
import { ChatEvents } from ".";
/**
 * 
 */
export class MessageCenter extends EventEmitter implements MessageCenterInterface {

    /**
     * main location in realtime database
     * where all the chats are stored
     */
    protected mainRef = "messageCenterChats";
    protected countsRef = "messageCenterCounts";
    protected userChatsRef = "messageCenterUserChats";

    public currentUserId?: string;
    public unreadCount: number = 0;

    private onUnreadCountChange?: (snapshot: firebase.database.DataSnapshot) => void;
    private onNewChatCreation?: (snapshot: firebase.database.DataSnapshot) => void;

    /**
     * 
     * @param services 
     * @param log 
     */
    constructor(protected services: MessageCenterServicesType, private log: LogType = Log(`MessageCenter: `)) {
        super();
        this.onUnreadCountChange = this.createOnUnreadCountChangeListener();
        this.listenForTotalUnreadCount(); // initial event registrations
        this.onNewChatCreation = this.createNewChatCreationListner();
        this.listenForNewChat();
        this.services.auth.onAuthStateChanged((user) => {
            if (user && user.uid !== this.currentUserId) {
                this.currentUserId = user.uid;
                this.listenForTotalUnreadCount(); // on state change event registrations
                this.listenForNewChat();
                this.setUnreadCount();
            }
        });
    }

    private setUnreadCount() {
        if (this.currentUserId) {
            this.services.realtime.ref(this.countsRef).child("unread").child(this.currentUserId).once("value").then(async snapshot => {
                const unreadCounts: { [threadName: string]: number } = await snapshot.val();
                const totalUnreadCount = Object.values(unreadCounts).reduce((acc, count) => {
                    return acc + count;
                }, 0);
                this.unreadCount = totalUnreadCount;
            })
        }
    }

    async getChats(limit: number, offset: number) {
        try {
            if (!this.services.auth.currentUser?.uid) {
                throw new Error(`NO_SIGNED_IN_USER_FOUND`);
            }
            const connectedPeople: ConnectedPeopleType[] = await this.getConnectedPeople(limit, offset);
            //create Chat objects here and return
            const chats = connectedPeople.map(p => {
                const id = p.chatMeta.id;
                return new Chat(id, {
                    auth: this.services.auth,
                    storage: this.services.storage,
                    functions: this.services.functions,
                    realtimeRef: this.services.realtime.ref(this.mainRef).child(id),
                    unreadCountRef: this.services.realtime.ref(this.countsRef).child("unread"),
                    sentCountRef: this.services.realtime.ref(this.countsRef).child("sent")
                }, this.log);
            });
            return chats;
        } catch (e) {
            this.log.error(`Error while creating chat objects: `, e);
        }
        return [];
    }

    async getConnectedPeople(limit: number, offset: number) {
        return await this.services.functions.httpsCallable("messages")({
            actionType: "GET_MESSAGE_CENTER_CONNECTED_PEOPLE",
            limit,
            offset
        }).then(({ data }: any) => {
            if (data.status !== "success") {
                throw new Error(data.message || data.code || `An error has occurred in getting connected people`);
            }
            return data.data;
        });
    }

    private async listenForTotalUnreadCount() {
        if (!this.services.auth.currentUser?.uid || !this.onUnreadCountChange) {
            return;
        }
        const ref = this.services.realtime.ref(this.countsRef)
            .child("unread")
            .child(this.services.auth.currentUser.uid);
        //first detach any previous listener and then attach one
        ref.off("value", this.onUnreadCountChange);
        ref.on("value", this.onUnreadCountChange);
    }

    private createOnUnreadCountChangeListener() {
        return (snapshot: firebase.database.DataSnapshot) => {
            if (!snapshot.exists()) {
                return;
            }
            const unreadCounts: { [threadName: string]: number } = snapshot.val();
            const totalUnreadCount = Object.values(unreadCounts).reduce((acc, count) => {
                return acc + count;
            }, 0);
            this.unreadCount = totalUnreadCount;
            this.emit(CountEvents.Unread, totalUnreadCount);
        }
    }

    private async listenForNewChat() {
        if (!this.services.auth.currentUser?.uid || !this.onNewChatCreation) {
            return;
        }

        const ref = this.services.realtime.ref(this.userChatsRef)
            .child(this.services.auth.currentUser.uid);
        //first detach any previous listener and then attach one
        ref.off("child_changed", this.onNewChatCreation);
        ref.on("child_changed", this.onNewChatCreation);
    }

    private createNewChatCreationListner() {
        return (snapshot: firebase.database.DataSnapshot) => {
            if (!snapshot.exists()) {
                return;
            }
            const userChatIds: string[] = snapshot.val();
            this.emit(ChatEvents.CREATED, userChatIds);
        }
    }
}